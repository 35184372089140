import React, { Component } from "react";
import { ChevronDown, List, X } from "react-bootstrap-icons";

import "./Navmenu.css";

class NavMenu extends Component {
  state = {
    mobileNavActive: false,
    dropdownsActive: [],
    activeLink: "",
  };

  componentDidMount() {
    window.addEventListener("load", this.handleNavmenuScrollspy);
    document.addEventListener("scroll", this.handleNavmenuScrollspy);
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.handleNavmenuScrollspy);
    document.removeEventListener("scroll", this.handleNavmenuScrollspy);
  }

  handleMobileNavToggle = () => {
    this.setState((prevState) => ({
      mobileNavActive: !prevState.mobileNavActive,
    }));
  };

  handleNavLinkClick = (hash) => {
    this.setState({ activeLink: hash, mobileNavActive: false });
  };

  handleDropdownToggle = (index) => {
    this.setState((prevState) => {
      const dropdownsActive = [...prevState.dropdownsActive];
      dropdownsActive[index] = !dropdownsActive[index];
      console.log(`handleDropdownToggle: ${index} ${dropdownsActive[index]}`);
      return { dropdownsActive };
    });
  };

  handleNavmenuScrollspy = () => {
    const navmenulinks = document.querySelectorAll(".navmenu a");
    navmenulinks.forEach((navmenulink) => {
      if (!navmenulink.hash) return;
      const section = document.querySelector(navmenulink.hash);
      if (!section) return;
      const position = window.scrollY + 200;
      if (
        position >= section.offsetTop &&
        position <= section.offsetTop + section.offsetHeight
      ) {
        this.setState({ activeLink: navmenulink.hash });
      }
    });
  };

  renderMenu = (menu, isSubmenu = false, dropdownIndex = 0) => {
    const { activeLink, dropdownsActive } = this.state;

    return (
      <ul
        className={
          isSubmenu
            ? dropdownsActive[dropdownIndex - 1]
              ? "dropdown-active"
              : ""
            : ""
        }
      >
        {Object.keys(menu).map((key, index) => {
          const item = menu[key];
          if (typeof item === "string") {
            return (
              <li key={index}>
                <a
                  href={item}
                  className={activeLink === item ? "active" : ""}
                  onClick={() => this.handleNavLinkClick(item)}
                >
                  {key}
                </a>
              </li>
            );
          } else {
            return (
              <li
                key={index}
                className={`dropdown ${
                  dropdownsActive[dropdownIndex] ? "active" : ""
                }`}
              >
                <a
                  href="#"
                  onClick={(e) => {
                    e.preventDefault();
                    this.handleDropdownToggle(dropdownIndex);
                  }}
                >
                  <span>{key}</span> &nbsp;
                  <i>
                    <ChevronDown className="toggle-dropdown" />
                  </i>
                </a>
                {this.renderMenu(item, true, dropdownIndex + 1)}
              </li>
            );
          }
        })}
      </ul>
    );
  };

  render() {
    const { menu } = this.props;
    const { mobileNavActive } = this.state;

    return (
      <nav
        id="navmenu"
        className={`navmenu ${mobileNavActive ? "mobile-nav-active" : ""}`}
      >
        {this.renderMenu(menu)}
        {mobileNavActive ? (
          <X
            className="mobile-nav-toggle d-xl-none px-4"
            onClick={this.handleMobileNavToggle}
          />
        ) : (
          <List
            className="mobile-nav-toggle d-xl-none"
            onClick={this.handleMobileNavToggle}
          />
        )}
      </nav>
    );
  }
}

export default NavMenu;
