import React, { Component } from "react";
import Typewriter from "typewriter-effect";
import VideoPlayer from "../utils/video-player";
import { BlurhashCanvas } from "react-blurhash";

import "./Hero.css";

class Hero extends Component {

  componentDidMount() {
    window.addEventListener("load", this.playVideo);
  }

  playVideo() {
    const videoTag = document.querySelector("#background-video");
    if (videoTag) {
      videoTag.play();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("load", this.playVideo);
  }


  render() {
    const { menu } = this.props;

    return (
      <section id="hero" className="hero section dark-background">
        <div className="hero-cover">
          <BlurhashCanvas
            className="hero-cover"
            hash={"|29$^5E2s:I:9aM|slWCRj}[NuxFo2t7kCt7bHNG^Qt79a$*f+RjR*j[oz^P4:NH-oxu?GX8xt9a=yofxZE2%2WVRkxZt6%2s:Ip%1xFR+t6I:n%tR-oI:NGE2oJRknixaR*s:ayE2oLNHR+slj@$%j[xa-Ut7IpoyS4WC"}
            height={20}
            width={15}
            punch={1}
          />
        </div>
        <img src="/video/hamedan.dark/hamedan.dark.jpg" />
        {/* <VideoPlayer src="/video/hamedan.dark/hamedan.dark.m3u8" /> */}
        <video autoplay="autoplay" loop="loop" muted defaultMuted playsinline  oncontextmenu="return false;"  preload="auto" id="background-video">
          <source src="/video/hamedan.dark/hamedan.dark.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        {/* <VideoPlayerWithBlurhash
          blurhash="|29$^5E2s:I:9aM|slWCRj}[NuxFo2t7kCt7bHNG^Qt79a$*f+RjR*j[oz^P4:NH-oxu?GX8xt9a=yofxZE2%2WVRkxZt6%2s:Ip%1xFR+t6I:n%tR-oI:NGE2oJRknixaR*s:ayE2oLNHR+slj@$%j[xa-Ut7IpoyS4WC"
          imgSrc="/video/hamedan.dark/hamedan.dark.jpg"
          videoSrc="/video/hamedan.dark/hamedan.dark.m3u8"
        /> */}

        <div
          className="container d-flex flex-column align-items-center text-center mt-auto justify-content-center"
          style={{ minHeight: 200 }}
        >
          <h2>
            <Typewriter
              options={{
                skipAddStyles: true,
                cursor: "",
              }}
              onInit={(typewriter) => {
                typewriter
                  .pauseFor(500)
                  .typeString("همدان")
                  .pauseFor(3000)
                  .typeString("<span> من<span>")
                  .start();
              }}
            />
          </h2>
          <h3 data-aos="zoom-in" data-aos-delay="2000" className="p-4">
            پایتخت تاریخ و تمدن ایران
          </h3>

          {/* <p data-aos="fade-up" data-aos-delay="200">
            10-12 December, Downtown Conference Center, New York
          </p>
          <div data-aos="fade-up" data-aos-delay="300" className="">
            <a
              href="https://www.youtube.com/watch?v=LXb3EKWsInQ"
              className="glightbox pulsating-play-btn mt-3"
            ></a>
          </div> */}
        </div>

        <div
          data-aos="fade-up"
          data-aos-delay="6000"
          className="about-info mt-auto position-relative"
        >
          <div className="container position-relative">
            <div className="row" dir="rtl">
              <div className="col-lg-6">
                <h2>نیم نگاه تاریخی</h2>
                <p className="text-justify pt-2">
                  همدان یکی از قدیمی‌ترین شهرهای ایران با تاریخی بیش از ۳۰۰۰ سال
                  است که در دوران مادها با نام هگمتانه به عنوان پایتخت انتخاب
                  شد. این شهر در طول تاریخ تحت حکومت‌های مختلفی مانند هخامنشیان،
                  سلوکیان، اشکانیان، ساسانیان و سلجوقیان بوده است. از جاذبه‌های
                  تاریخی همدان می‌توان به تپه هگمتانه، گنبد علویان و آرامگاه ابن
                  سینا اشاره کرد. همدان علاوه بر تاریخ غنی خود، دارای طبیعت
                  زیبایی نیز می‌باشد که کوهستان الوند نمونه‌ای از آن است.
                </p>
              </div>
              {/* <div className="col-lg-3">
                <h3>شهر هوشمند</h3>
                <p>سایت همدان با استفاده از فناوری‌های نوین، خدمات شهری را به صورت دیجیتال به شهروندان ارائه می‌دهد</p>
              </div> 
              <div className="col-lg-3">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d53634.01657473735!2d48.510236057402096!3d34.79897800254037!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ff1ecc4b3aab593%3A0x7b0b82ddf4074b5b!2sHamedan%2C%20Hamadan%20Province!5e0!3m2!1sen!2s!4v1720004500056!5m2!1sen!2s"
                  height="200"
                  style={{ border: 0, width: "100%" }}
                  allowFullScreen=""
                  loading="lazy"
                  referrerPolicy="no-referrer-when-downgrade"
                ></iframe> 
              </div>*/}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Hero;
