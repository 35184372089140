import React, { Component } from "react";

import Preloader from "./components/modal/preloader/Preloader";
import Header from "./components/header/Header";
import Hero from "./components/hero/Hero";
import Footer from "./components/footer/Footer";
import ScrollTop from "./components/modal/scrolltop/ScrollTop";

import AOS from "./vendor/aos/src/js/aos";
import "./vendor/aos/dist/aos.css";

class App extends Component {
  componentDidMount() {
    // Apply .scrolled class to the body as the page is scrolled down
    // const toggleScrolled = () => {
    //   const selectBody = document.querySelector("body");
    //   const selectHeader = document.querySelector("#header");
    //   if (
    //     !selectHeader.classList.contains("scroll-up-sticky") &&
    //     !selectHeader.classList.contains("sticky-top") &&
    //     !selectHeader.classList.contains("fixed-top")
    //   )
    //     return;
    //   window.scrollY > 100
    //     ? selectBody.classList.add("scrolled")
    //     : selectBody.classList.remove("scrolled");
    // };

    // document.addEventListener("scroll", toggleScrolled);
    // window.addEventListener("load", toggleScrolled);

    // Scroll top button
    // const scrollTop = document.querySelector(".scroll-top");

    // const toggleScrollTop = () => {
    //   if (scrollTop) {
    //     window.scrollY > 100
    //       ? scrollTop.classList.add("active")
    //       : scrollTop.classList.remove("active");
    //   }
    // };

    // if (scrollTop) {
    //   scrollTop.addEventListener("click", (e) => {
    //     e.preventDefault();
    //     window.scrollTo({
    //       top: 0,
    //       behavior: "smooth",
    //     });
    //   });
    // }

    // window.addEventListener("load", toggleScrollTop);
    // document.addEventListener("scroll", toggleScrollTop);

    AOS.init({
      duration: 600,
      easing: "ease-in-out",
      once: true,
      mirror: false,
    });

    // Init swiper sliders
    // const initSwiper = () => {
    //   document
    //     .querySelectorAll(".init-swiper")
    //     .forEach(function (swiperElement) {
    //       let config = JSON.parse(
    //         swiperElement.querySelector(".swiper-config").innerHTML.trim()
    //       );

    //       if (swiperElement.classList.contains("swiper-tab")) {
    //         window.initSwiperWithCustomPagination(swiperElement, config);
    //       } else {
    //         new window.Swiper(swiperElement, config);
    //       }
    //     });
    // };

    // window.addEventListener("load", initSwiper);

    // Frequently Asked Questions Toggle
    const faqItems = document.querySelectorAll(
      ".faq-item h3, .faq-item .faq-toggle"
    );
    faqItems.forEach((faqItem) => {
      faqItem.addEventListener("click", () => {
        faqItem.parentNode.classList.toggle("faq-active");
      });
    });

    // Correct scrolling position upon page load for URLs containing hash links.
    // window.addEventListener("load", function (e) {
    //   if (window.location.hash) {
    //     const section = document.querySelector(window.location.hash);
    //     if (section) {
    //       setTimeout(() => {
    //         let scrollMarginTop = getComputedStyle(section).scrollMarginTop;
    //         window.scrollTo({
    //           top: section.offsetTop - parseInt(scrollMarginTop),
    //           behavior: "smooth",
    //         });
    //       }, 100);
    //     }
    //   }
    // });
  }

  componentWillUnmount() {
    // const scrollTop = document.querySelector(".scroll-top");
    // if (scrollTop) {
    //   scrollTop.removeEventListener("click", this.scrollTopHandler);
    // }
    // window.removeEventListener("load", this.initSwiper);
    // window.removeEventListener("load", this.correctScrollPosition);
  }

  render() {
    return (
      <>
        <Preloader />
        <Header
          menu={{
            // Dropdown: {
            //   "Dropdown 1": "#dropdown1",
            //   "Deep Dropdown": {
            //     "Deep Dropdown 1": "#deep1",
            //   },
            // },
            "تماس با ما": "#contact",
            گالری: "#gallery",
            مشاغل: "#jobs",
            "هتل ها": "#hotels",
            خانه: "#hero",
          }}
          sitename={"همدان"}
          cta={{
            label: "سفر به همدان",
            href: "#buy-ticket",
          }}
          aos={{
            effect: "zoom-in",
            delay: "4000",
          }}
        ></Header>

        <main className="main">
          <Hero></Hero>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d26212.526851712104!2d48.51345572763083!3d34.79170315376131!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3ff1ecc4b3aab593%3A0x7b0b82ddf4074b5b!2sHamedan%2C%20Hamadan%20Province%2C%20Iran!5e0!3m2!1sen!2sus!4v1720008371643!5m2!1sen!2sus"
            height="200"
            style={{ border: 0, width: "100%" }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </main>
        <Footer></Footer>

        <ScrollTop></ScrollTop>
      </>
    );
  }
}

export default App;
