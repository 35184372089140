import React, { Component, createRef } from "react";
import ReactDOM from "react-dom";

import "./ScrollTop.css";

import { ArrowUpShort } from "react-bootstrap-icons";

class ScrollTop extends Component {
  constructor(props) {
    super(props);
    this.scrollTopRef = createRef();
    this.toggleScrolled = this.toggleScrolled.bind(this);
    this.toggleScrollTop = this.toggleScrollTop.bind(this);
    this.handleClick = this.handleClick.bind(this);
  }

  componentDidMount() {
    window.addEventListener("scroll", this.toggleScrolled);
    window.addEventListener("scroll", this.toggleScrollTop);
    window.addEventListener("load", this.toggleScrolled);
    window.addEventListener("load", this.toggleScrollTop);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.toggleScrolled);
    window.removeEventListener("scroll", this.toggleScrollTop);
    window.removeEventListener("load", this.toggleScrolled);
    window.removeEventListener("load", this.toggleScrollTop);
  }

  toggleScrolled() {
    const selectBody = document.querySelector("body");
    const selectHeader = document.querySelector("#header");
    if (
      !selectHeader.classList.contains("scroll-up-sticky") &&
      !selectHeader.classList.contains("sticky-top") &&
      !selectHeader.classList.contains("fixed-top")
    )
      return;
    window.scrollY > 100
      ? selectBody.classList.add("scrolled")
      : selectBody.classList.remove("scrolled");
  }

  toggleScrollTop() {
    if (this.scrollTopRef.current) {
      window.scrollY > 100
        ? this.scrollTopRef.current.classList.add("active")
        : this.scrollTopRef.current.classList.remove("active");
    }
  }

  handleClick(e) {
    e.preventDefault();
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  render() {
    return ReactDOM.createPortal(
      <a
        href="#"
        id="scroll-top"
        className="scroll-top d-flex align-items-center justify-content-center"
        ref={this.scrollTopRef}
        onClick={this.handleClick}
      >
        <i>
          <ArrowUpShort />
        </i>
      </a>,
      document.body
    );
  }
}

export default ScrollTop;