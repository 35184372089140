import React, { Component } from "react";

import { TwitterX, Instagram, Youtube } from "react-bootstrap-icons";

import "./Footer.css";

class Footer extends Component {
  render() {
    return (
      <footer id="footer" className="footer dark-background">
        <div className="footer-top" dir="rtl">
          <div className="container">
            <div className="row gy-4">
              <div className="col-lg-2 col-md-3 footer-about">
                {/* <a href="index.html" className="logo d-flex align-items-center">
                  <span className="sitename"></span>
                </a> */}
                <div className="footer-contact">
                  <h4>تماس با ما</h4>
                  {/* <p>A108 Adam Street</p>
                  <p>New York, NY 535022</p>
                   <p className="mt-3">
                    <strong>Phone:</strong> <span>+1 5589 55488 55</span>
                  </p> */}
                  <p>
                    <strong>پست الکترونیکی</strong>
                  </p>{" "}
                  <p>hi@hamedan.city</p>
                </div>
              </div>

              <div className="col-lg-2 col-md-3 footer-links">
                <h4>شهر الکترونیگ</h4>
                <ul>
                  <li>
                    <a href="#">اماکن تفریحی</a>
                  </li>
                  <li>
                    <a href="#">رزرو بلیط</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-md-3 footer-links">
                <h4>لینک های مهم</h4>
                <ul>
                  <li>
                    <a href="https://ostan-hm.ir/">استانداری</a>
                  </li>
                  <li>
                    <a href="https://hamedan.ir/">شهرداری</a>
                  </li>
                  <li>
                    <a href="https://sinamet.ir/">اداره کل هواشناسی</a>
                  </li>
                  <li>
                    <a href="https://basu.ac.ir/">دانشگاه بوعلی سینا</a>
                  </li>
                  <li>
                    <a href="https://fardayehamedan.ir/">پایگاه خبری فردای همدان</a>
                  </li>
                </ul>
              </div>
              {/*
              <div className="col-lg-2 col-md-3 footer-links">
                <h4>Hic solutasetp</h4>
                <ul>
                  <li>
                    <a href="#">Molestiae accusamus iure</a>
                  </li>
                  <li>
                    <a href="#">Excepturi dignissimos</a>
                  </li>
                  <li>
                    <a href="#">Suscipit distinctio</a>
                  </li>
                  <li>
                    <a href="#">Dilecta</a>
                  </li>
                  <li>
                    <a href="#">Sit quas consectetur</a>
                  </li>
                </ul>
              </div>

               <div className="col-lg-2 col-md-3 footer-links">
                <h4>Nobis illum</h4>
                <ul>
                  <li>
                    <a href="#">Ipsam</a>
                  </li>
                  <li>
                    <a href="#">Laudantium dolorum</a>
                  </li>
                  <li>
                    <a href="#">Dinera</a>
                  </li>
                  <li>
                    <a href="#">Trodelas</a>
                  </li>
                  <li>
                    <a href="#">Flexo</a>
                  </li>
                </ul>
              </div> */}

              <div className="col-lg-6 col-md-3 footer-links">
                <ul >
                  <li style={{placeContent: "center"}}>
                    <a href="#">
                      <img src="/img/appstore.png" width={180} />
                    </a>
                  </li>
                  <li style={{placeContent: "center"}}> 
                    <a href="#">
                      <img src="/img/playstore.png" width={180} />
                    </a>
                  </li>
                  <li style={{placeContent: "center"}}>
                    <a href="#">
                      <img src="/img/cafebazaar.png" width={180} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="copyright text-center">
          <div className="container d-flex flex-column flex-lg-row justify-content-center justify-content-lg-between align-items-center">
            <div className="social-links order-first order-lg-last mb-3 mb-lg-0">
              <a href="">
                <i>
                  <Instagram></Instagram>
                </i>
              </a>
              <a href="">
                <i>
                  <Youtube></Youtube>
                </i>
              </a>
              <a href="">
                <i>
                  <TwitterX></TwitterX>
                </i>
              </a>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default Footer;
