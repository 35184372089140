import React, { Component } from "react";

import NavMenu from "./navmenu/Navmenu";

import "./Header.css";

class Header extends Component {
  render() {
    const { menu, sitename, cta } = this.props;

    let aos = {
      effect: "zoom-in",
      delay: "2000",
    };
    // console.log(this.props.aos);
    // if (this.props.aos != null) {
    //   aos = this.props.aos;
    // }

    return (
      <header
        id="header"
        className="header d-flex align-items-center fixed-top"
      >
        <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-between">
          {/* <div className="container-fluid container-xl position-relative d-flex align-items-center justify-content-end"> */}
          {/* <a
            href="index.html"
            className="logo d-flex align-items-center me-auto"
            data-aos={aos.effect}
            data-aos-delay={aos.delay}
          >
            <img src="assets/img/logo.png" alt="" />
            <h1 className="sitename">{sitename}</h1>
          </a>  */}

          <a
            data-aos={aos.effect}
            data-aos-delay={aos.delay}
            className="cta-btn d-none d-sm-block"
            href={cta.href}
          >
            {cta.label}
          </a>
          <div data-aos={aos.effect} data-aos-delay={aos.delay}>
            <NavMenu menu={menu} />
          </div>
        </div>
      </header>
    );
  }
}

export default Header;
