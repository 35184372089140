import React, { Component, createRef } from "react";
import ReactDOM from "react-dom";

import "./Preloader.css";

class Preloader extends Component {
  constructor(props) {
    super(props);
    this.preloaderRef = createRef();
  }

  componentDidMount() {
    window.addEventListener('load', this.handleLoad);
    if (document.readyState === 'complete') {
      this.handleLoad();
    }
  }


  componentWillUnmount() {
    window.removeEventListener("load", this.handleLoad);
  }

  handleLoad = () => {
    if (this.preloaderRef.current) {
      this.preloaderRef.current.remove();
    }
  };

  render() {
    return ReactDOM.createPortal(
      <div id="preloader" ref={this.preloaderRef}></div>,
      document.body
    );
  }
}

export default Preloader;
